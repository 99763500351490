import ProductService from "@/services/ProductService.js";

const state = {
  itemList: [],
};

const getters = {
  getItemList(state) {
    return state.itemList;
  },
  getItem(state, index) {
    return state.itemList[index];
  },
  getPrice(state, purchasing_price, surcharge) {
    return Number(
      (Number.parseFloat(purchasing_price) *
        (Number.parseInt(surcharge) + 100)) /
        100
    ).toFixed(2);
  },
};

const actions = {
  getLastID(mandant = "all") {
    return new Promise((resolve, reject) => {
      ProductService.getLastItem(mandant)
        .then((result) => {
          if (result && result.data && result.data.item) {
            resolve(result.data.item.productnr);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deleteItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      ProductService.delete(item._id)
        .then(() => {
          commit("DELETE_ITEM", item);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchItemList({ commit }) {
    return new Promise((resolve, reject) => {
      ProductService.getAll()
        .then((response) => {
          commit("SET_ITEM_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ITEM_LIST(state, value) {
    state.itemList = value;
  },
  DELETE_ITEM(state, item) {
    state.itemList = state.itemList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
