import Vue from "vue";
import App from "./App.vue";
import router from "./router/index.js";
import store from "./store/index.js";
import vuetify from "./plugins/vuetify";
import Notifications from "vue-notification";
import DataService from "./services/DataService";
import VCurrencyField from "v-currency-field";
import { VTextField } from "vuetify/lib"; //Globally import VTextField
import VuetifyConfirm from "vuetify-confirm";

Vue.component("v-text-field", VTextField);
Vue.use(VCurrencyField, {
  locale: "de-DE",
  decimalLength: 2,
  autoDecimalMode: false,
  min: null,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
});

Vue.use(VuetifyConfirm, {
  vuetify,
  buttonTrueText: "Löschen",
  buttonFalseText: "Abbrechen",
  color: "warning",
  icon: "mdi-alert",
  title: "Eintrag löschen",
  width: 350,
  property: "$confirm"
});

Vue.use(Notifications);

DataService.apiClient.interceptors.response.use(
  //Antwort  im Erfolgsfall zurück geben
  (response) => {
    return response;
  },
  //Fehler behandeln
  (error) => {
    //console.log("Fehler",error);
    if (error.response && error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    } else if (error.response && error.response.status === 401) {
      const origRequest = error.config;
      //console.log("ERROR", error.response);
      // if (error.response.data.error == "Token expired") {
      //disable alerts on refreshing
      //}

      if (!origRequest._retry) {
        origRequest._retry = true;
        store
          .dispatch("user/refreshToken")
          .then((result) => {
            if (result.status !== 200) {
              //logout
              store.dispatch("user/clearStoredUser").then(() => {
                router.push({ name: "login" });
                //return Promise.reject(error);
                return Promise.resolve(result);
              });
            } else {
              //resend original request with new token
              return DataService.apiClient(origRequest);
            }
          })
          .catch((error) => {
            store.dispatch("user/clearStoredUser").finally(() => {
              router.push({ name: "login" });
              return Promise.reject(error);
            });
          });
      }
      //Fehler zurück geben da sonst die Fehlerhandling Kette unterbrochen wird
      return Promise.reject(error);
    } else if (error.response && error.response.status === 403) {
      // Logout user if token refresh didn't work or user is disabled
      store.dispatch("user/logout").finally(() => {
        router.push({ name: "login" });
        return Promise.reject(error);
      });

      // Ende Logout
    }
  }
);

Vue.config.productionTip = false;

export const eventBus = new Vue();
new Vue({
  store,
  router,
  vuetify,
  created() {},
  render: (h) => h(App)
}).$mount("#app");
