import Vue from "vue";
import VueRouter from "vue-router";
import userRoles from "@/helpers/userRoles.js";

const DEFAULT_TITLE =
  process.env.VUE_APP_NAME +
  " " +
  process.env.VUE_APP_VERSION +
  " von datafieber.com";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Login.vue"),
    meta: {
      title: "DataLine CRM von datafieber.com",
    },
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () => import("../views/Dashboard.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/offers",
    name: "offers",
    component: () => import("../views/offers/OfferMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/offer/edit/:id?",
    name: "offer-edit",
    component: () => import("../views/offers/OfferForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/confirmations",
    name: "confirmations",
    component: () => import("../views/confirmations/ConfirmationMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/confirmation/edit/:id?",
    name: "confirmation-edit",
    component: () => import("../views/confirmations/ConfirmationForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/deliveries",
    name: "deliveries",
    component: () => import("../views/deliverynotes/DeliveryNotesMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/delivery/edit/:id?",
    name: "delivery-edit",
    component: () => import("../views/deliverynotes/DeliveryNotesForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/bills",
    name: "bills",
    component: () => import("../views/bills/BillMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/bill/edit/:id?",
    name: "bill-edit",
    component: () => import("../views/bills/BillForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/kontakte",
    name: "kontakte",
    component: () => import("../views/contacts/KontaktMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/kontakt/edit/:id?",
    name: "kontakt-edit",
    component: () => import("../views/contacts/KontaktEdit.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },

  {
    path: "/products",
    name: "products",
    component: () => import("../views/products/ProductMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/product/edit/:id?",
    name: "product-edit",
    component: () => import("../views/products/ProductEdit.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },

  {
    path: "/mitarbeiter",
    name: "mitarbeiter",
    component: () => import("../views/MitarbeiterMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/mitarbeiter/edit",
    name: "mitarbeiter-edit",
    props: true,
    component: () => import("../views/MitarbeiterForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/settings",
    name: "settings",
    component: () => import("../views/SettingsForm.vue"),
    meta: { requiresAuth: true, role: userRoles.SuperUser },
  },
  {
    path: "/mandanten",
    name: "mandanten",
    component: () => import("../views/clients/MandantenMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.SuperUser },
  },
  {
    path: "/mandant/edit/:id?",
    name: "mandant-edit",
    component: () => import("../views/clients/MandantenForm.vue"),
    meta: { requiresAuth: true, role: userRoles.SuperUser },
  },
  {
    path: "/user",
    name: "user",
    component: () => import("../views/UserMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.SuperUser },
  },
  {
    path: "/user/edit",
    name: "user-edit",
    props: true,
    component: () => import("../views/UserForm.vue"),
    meta: { requiresAuth: true, role: userRoles.SuperUser },
  },
  {
    path: "/pdftemplates",
    name: "pdftemplates",
    component: () => import("../views/pdfTemplates/PDFTemplateMgmt.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/pdftemplate/edit/:id?",
    name: "pdftemplate-edit",
    component: () => import("../views/pdfTemplates/PDFTemplateForm.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/importexport",
    name: "importexport",
    component: () => import("../views/importexport/Overview.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/importexport/importwizard",
    name: "importwizard",
    component: () => import("../views/importexport/ImportWizard.vue"),
    meta: { requiresAuth: true, role: userRoles.Manager },
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/404.vue"),
  },
  {
    path: "*",
    redirect: { name: "404" },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

/*
router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("user");

  if (to.matched.some(record => record.meta.requiresAuth) && !loggedIn) {
    //next("/login");
    this.$store.dispatch("logout").then(() => {
      next("/login");
    });
  }
  next();
});
*/
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    let storeageUser = localStorage.getItem("user");
    if (storeageUser && JSON.parse(storeageUser) == null) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      let user = JSON.parse(localStorage.getItem("user"));
      if (to.matched.some((record) => record.meta.role)) {
        if (
          user.apps.roles.indexOf(userRoles.SuperUser) >= 0 ||
          user.apps.roles.indexOf(to.meta.role) >= 0
        ) {
          next();
        } else {
          next({ name: "offers" });
        }
      } else {
        next();
      }
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  Vue.nextTick(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
  });
});

export default router;
