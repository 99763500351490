import DataService from "@/services/DataService";

export default {
  getAll() {
    return DataService.apiClient.get("/product");
  },

  get(id) {
    return DataService.apiClient.get("/product/" + id);
  },
  findByName(name, mid = "all") {
    return DataService.apiClient.post("/product/findbyname/", { name, mid });
  },
  getLastItem() {
    return DataService.apiClient.get("/product/last/");
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/product/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/product/add", item.data);
    }
  },
  delete(id) {
    return DataService.apiClient.delete("/product/delete/" + id);
  },
};
