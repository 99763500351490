import ContactService from "@/services/ContactService.js";

const state = {
  contactList: [],
  contact: null,
  perPage: 100,
  page: 1,
  totalPages: 1,
  search: "",
};

const getters = {
  getContactList(state) {
    return state.contactList;
  },
  getCurrent(state) {
    return state.contact;
  },
  getPage(state) {
    return state.page;
  },
  getPerPage(state) {
    return state.perPage;
  },
  getTotalPages(state) {
    return state.totalPages;
  },
  getSearch(state) {
    console.log(state.search);
    return state.search;
  },
};

const actions = {
  findCustomers(name) {
    return new Promise((resolve, reject) => {
      ContactService.findCustomers(name)
        .then((result) => {
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  getLastID() {
    return new Promise((resolve, reject) => {
      ContactService.getLastItem()
        .then((result) => {
          if (result && result.data && result.data.item) {
            resolve(result.data.item.kdnr);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchContactList({ state, commit }, page = null, perPage = null, query = "") {
    return new Promise((resolve, reject) => {
      if (page) {
        state.page = page;
      }
      if (perPage) {
        state.perPage = perPage;
      }
      if (query) {
        state.search = query;
      }
      ContactService.getList(state.page, state.perPage, state.search)
        .then((response) => {
          commit("SET_CONTACT_LIST", response.data);
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchContact({ commit }, id) {
    return new Promise((resolve, reject) => {
      ContactService.get(id)
        .then((response) => {
          commit("SET_CONTACT", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_CONTACT_LIST(state, value) {
    state.contactList = value.data;
    state.totalPages = value.totalPages;
    state.perPage = value.perPage;
  },
  SET_LIST_PER_PAGE(state, value) {
    state.perPage = parseInt(value);
  },
  SET_LIST_PAGE(state, value) {
    state.page = parseInt(value);
  },
  SET_CONTACT(state, value) {
    state.contact = value;
  },
  SET_SEARCH(state, value) {
    console.log(value);
    if (!value) {
      value = "";
    }
    state.search = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
