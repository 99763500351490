import DataService from "@/services/DataService";

export default {
  getAll(mandant = null) {
    if (mandant) {
      return DataService.apiClient.get("/delivery/" + mandant);
    } else {
      return DataService.apiClient.get("/delivery");
    }
  },

  getByContact(mandant, contactId) {
    if (!contactId || !mandant) {
      return null;
    }

    return DataService.apiClient.get(
      `/delivery/contact/${mandant}/${contactId}`
    );
  },

  get(id, mandant) {
    return DataService.apiClient.get("/delivery/" + mandant + "/" + id);
  },
  getNextItem(mandant) {
    return DataService.apiClient.get("/mandant/" + mandant + "/next/delivery");
  },
  makePdf(delivery) {
    return DataService.apiClient.post(
      "/pdf/create",
      {
        templateData: delivery,
        templateID: delivery.mandantData.pdfTemplates.deliveryTemplate,
      },
      { responseType: "blob" }
    );
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch(
        "/delivery/edit/" + item.id,
        item.data
      );
    } else {
      return DataService.apiClient.post("/delivery/add", item.data);
    }
  },
  delete(id, mandant) {
    return DataService.apiClient.delete("/delivery/" + mandant + "/" + id);
  },
};
