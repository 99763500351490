import MandantenService from "@/services/MandantenService.js";

const state = {
  mandantenList: [],
  mandant: {
    _id: null,
    firmenname: "",
    firstname: "",
    lastname: "",
    adresse: "",
    plz: "",
    ort: "",
    land: "",
    email: "",
    web: "",
    telefon: "",
    fax: "",
    firmenbuchnr: "",
    uid: "",
    eori: "",
    steuernr: "",
    handelsgericht: "",
    bank: "",
    inhaber: "",
    blz: "",
    kontonr: "",
    iban: "",
    bic: "",
    logo: "",
    defaultPayDays: 14,
    defaultOfferValidDays: 30,
    nummernkreise: {
      offer: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      confirmation: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      delivery: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      bill: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      credit: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      reminder: {
        format: "{YYYY}-%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
      contact: {
        format: "%n4%",
        start: 1,
        lastNumber: 1,
        lastDate: null,
      },
    },
    pdfTemplates: {
      offerTemplate: null,
      confirmationTemplate: null,
      deliveryTemplate: null,
      billTemplate: null,
    },
    mailSettings: {
      mailnamefrom: "",
      mailadressfrom: "",
      useexternalsmtp: false,
      smtpserver: "",
      smtpport: 25,
      smtpauth: true,
      smtpuser: "",
      smtppass: "",
      smtptls: false,
    },
    uidcheckSettings: {
      tid: "",
      benid: "",
      pin: "",
    },
  },
  workingMandant: null,
};

const getters = {
  getMandantenList(state) {
    if (!state.mandantenList || state.mandantenList.length == 0) {
      state.mandantenList = JSON.parse(localStorage.getItem("mandantenList"));
    }
    return state.mandantenList;
  },
  getCurrent(state) {
    return state.mandant;
  },
  getWorkingMandant(state) {
    if (!state.workingMandant) {
      state.workingMandant = localStorage.getItem("workingMandant") || null;
    }
    return state.workingMandant;
  },
  getWorkingMandantData(state, getters) {
    let data = null;
    if (state.workingMandant) {
      data = getters.getMandantenList.find(
        (item) => item._id == state.workingMandant
      );
    }
    return data;
  },
  getMandantByName(state, name) {
    return state.mandantenList.find(
      (item) =>
        String(item.firmenname).toLowerCase() == String(name).toLowerCase()
    );
  },
};

const actions = {
  newMandant({ commit }) {
    commit("NEW_MANDANT");
  },
  setWorkingMandant({ commit }, id) {
    commit("SET_WORKING_MANDANT", id);
  },
  fetchMandantenList({ commit }) {
    return new Promise((resolve, reject) => {
      MandantenService.getAll()
        .then((response) => {
          if (response) {
            commit("SET_MANDANTEN_LIST", response.data.data);
            resolve(response);
          } else {
            reject("no data");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchMandant({ commit }, id) {
    return new Promise((resolve, reject) => {
      MandantenService.get(id)
        .then((response) => {
          if (response) {
            commit("SET_MANDANT", response.data.data);
            resolve(response);
          } else {
            reject("no data");
          }
        })
        .catch((error) => {
          console.log(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_MANDANT(state) {
    state.mandant = {
      firmenname: this.firmenbuchnr,
      firstname: this.firstname,
      lastname: this.lastname,
      adresse: this.adresse,
      plz: this.plz,
      ort: this.ort,
      land: this.land,
      email: this.email,
      web: this.web,
      telefon: this.telefon,
      fax: this.fax,
      firmenbuchnr: this.firmenbuchnr,
      uid: this.uid,
      eori: this.eori,
      steuernr: this.steuernr,
      handelsgericht: this.handelsgericht,
      bank: this.bank,
      inhaber: this.inhaber,
      blz: this.blz,
      kontonr: this.kontonr,
      iban: this.iban,
      bic: this.bic,
      logo: "",
      defaultPayDays: 14,
      defaultOfferValidDays: 30,
      nummernkreise: {
        offer: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        confirmation: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        delivery: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        bill: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        credit: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        reminder: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        contact: {
          format: "%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
      },
      pdfTemplates: {
        offerTemplate: "",
        confirmationTemplate: "",
        deliveryTemplate: "",
        billTemplate: "",
      },
      mailSettings: {
        mailnamefrom: "",
        mailadressfrom: "",
        useexternalsmtp: false,
        smtpserver: "",
        smtpport: 25,
        smtpauth: true,
        smtpuser: "",
        smtppass: "",
        smtptls: false,
      },
      uidcheckSettings: {
        tid: "",
        benid: "",
        pin: "",
      },
    };
  },
  SET_MANDANTEN_LIST(state, value) {
    state.mandantenList = value;
    localStorage.setItem("mandantenList", JSON.stringify(state.mandantenList));
  },
  SET_MANDANT(state, value) {
    state.mandant = value;

    if (!state.mandant.nummernkreise || !state.mandant.nummernkreise.offer) {
      state.mandant.nummernkreise = {
        offer: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        confirmation: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        delivery: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        bill: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        credit: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        reminder: {
          format: "{YYYY}-%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
        contact: {
          format: "%n4%",
          start: 1,
          lastNumber: 1,
          lastDate: null,
        },
      };
    }
    if (!state.mandant.mailSettings) {
      state.mandant.mailSettings = {
        mailnamefrom: "",
        mailadressfrom: "",
        useexternalsmtp: false,
        smtpserver: "",
        smtpport: 25,
        smtpauth: true,
        smtpuser: "",
        smtppass: "",
        smtptls: false,
      };
    }

    if (!state.mandant.pdfTemplates) {
      state.mandant.pdfTemplates = {
        offerTemplate: null,
        confirmationTemplate: null,
        deliveryTemplate: null,
        billTemplate: null,
      };
    }

    if (!state.mandant.uidcheckSettings) {
      state.mandant.uidcheckSettings = {
        tid: "",
        benid: "",
        pin: "",
      };
    }
  },
  SET_WORKING_MANDANT(state, id) {
    //const mandant = state.mandantenList.find((item) => item._id === id);
    state.workingMandant = id;
    localStorage.setItem("workingMandant", id);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
