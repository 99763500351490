import Vue from "vue";
import Vuex from "vuex";
import user from "./modules/user";
import mandant from "./modules/mandant";
import contact from "./modules/contact";
import offer from "./modules/offer";
import confirmation from "./modules/confirmation";
import delivery from "./modules/delivery";
import product from "./modules/product";
import bill from "./modules/bill";
import setting from "./modules/setting";
import pdfTemplate from "./modules/pdfTemplate";
import mail from "./modules/mail";
import countries from "./modules/countries";
import { version } from "../../package.json";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    appLoading: false,
    appVersion: version || "",
  },
  mutations: {
    SET_APP_LOADING(state, value) {
      state.appLoading = !!value;
    },
  },
  actions: {
    isCurrentDate(lastTimestamp) {
      let currentDate = new Date();
      let lastDate = new Date(lastTimestamp);

      return (
        currentDate.getFullYear() === lastDate.getFullYear() &&
        currentDate.getDate() === lastDate.getDate() &&
        currentDate.getMonth() === lastDate.getMonth()
      );
    },
  },
  getters: {
    appLoading(state) {
      return !!state.appLoading;
    },
    appVersion(state) {
      return state.appVersion;
    },
  },
  modules: {
    user,
    mandant,
    contact,
    offer,
    confirmation,
    product,
    setting,
    delivery,
    bill,
    pdfTemplate,
    mail,
    countries,
  },
});
