import ConfirmationService from "@/services/ConfirmationService.js";
import itemStatus from "@/helpers/itemStatus.js";

const state = {
  itemList: [],
  previewData: null,

  confirmation: {
    _id: null,
    confirmnr: "",
    status: itemStatus.Draft.code,
    date: Date.now,
    totalNetto: 0,
    totalBrutto: 0,
    mandant: "",
    customer: {},
    products: [],
    mwstList: [],
    origins: {
      offer: {},
    },
  },
};

const getters = {
  getItemList(state) {
    return state.itemList;
  },
  getPreviewData(state) {
    return state.previewData;
  },
  getCurrent(state) {
    return state.confirmation;
  },
};

const actions = {
  newConfirmation({ commit }) {
    commit("NEW_CONFIRMATION");
  },
  resetConfirmation({ commit }) {
    commit("NEW_CONFIRMATION");
  },
  setConfirmation({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        commit("SET_CONFIRMATION", data);
        resolve();
      } else {
        reject("Empty data");
      }
    });
  },
  getNextNr({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      ConfirmationService.getNextItem(mandant)
        .then((result) => {
          if (result && result.data) {
            resolve(result.data);
            commit();
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchConfirmation({ commit }, { id, mandant }) {
    return new Promise((resolve, reject) => {
      ConfirmationService.get(id, mandant)
        .then((result) => {
          commit("SET_CONFIRMATION", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveNewConfirmation({ commit, dispatch }, { item, mandant }) {
    //let item = JSON.parse(JSON.stringify(offerItem));
    return new Promise((resolve, reject) => {
      if (!mandant) {
        return reject(new Error("No mandant for saving set"));
      }
      dispatch("getNextNr", mandant._id)
        .then((result) => {
          item.lfdnr = result.lfdnr;
          item.confirmnr = result.item;
          item.templateID = mandant.pdfTemplates.confirmationTemplate;
          item.mandantData = mandant;

          //console.log(item);
          return ConfirmationService.save({ id: null, data: item });
        })
        .then(() => {
          //Reset confirmation state
          commit("NEW_CONFIRMATION");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveConfirmation({ commit }, item) {
    return new Promise((resolve, reject) => {
      ConfirmationService.save({
        id: item._id,
        data: item,
      })
        .then(() => {
          commit("NEW_CONFIRMATION");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addProduct({ commit }, item) {
    return new Promise((resolve) => {
      commit("ADD_PRODUCT", item);
      resolve(item);
    });
  },

  removeProduct({ commit }, index) {
    return new Promise((resolve) => {
      commit("REMOVE_PRODUCT", index);
      resolve(index);
    });
  },

  deleteItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      ConfirmationService.delete(item._id, item.mandant)
        .then(() => {
          commit("DELETE_ITEM", item);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchItemList({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      ConfirmationService.getAll(mandant)
        .then((response) => {
          commit("SET_CONFIRMATION_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_CONFIRMATION(state) {
    state.confirmation = {
      _id: null,
      confirmnr: "",
      status: itemStatus.Draft.code,
      date: Date.now(),
      totalNetto: 0,
      totalBrutto: 0,
      mandant: "",
      customer: {},
      products: [],
      mwstList: [],
      origins: {
        offer: {},
      },
    };
  },
  ADD_PRODUCT(state, value) {
    if (!state.confirmation) {
      state.confirmation = {};
    }

    if (!state.confirmation.products) {
      state.confirmation.products = [];
    }

    state.confirmation.products.push(value);
  },
  REMOVE_PRODUCT(state, index) {
    state.confirmation.products.splice(index, 1);
  },
  SET_CONFIRMATION_LIST(state, value) {
    state.itemList = value;
  },
  SET_CONFIRMATION(state, value) {
    state.confirmation = value;
  },
  DELETE_ITEM(state, item) {
    state.itemList = state.itemList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
