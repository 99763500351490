import MailService from "@/services/MailService.js";

const state = {
  mailSending: false
};

const getters = {
  isMailSending(state) {
    return state.mailSending;
  }
};

const actions = {
  sendMail({ commit }, maildata) {
    return new Promise((resolve, reject) => {
      commit("SET_MAILSEND_STATE", true);
      MailService.sendMail(maildata)
        .then(() => {
          resolve("success");
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          commit("SET_MAILSEND_STATE", false);
        });
    });
  }
};

const mutations = {
  SET_MAILSEND_STATE(state, status) {
    state.mailSending = status;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
