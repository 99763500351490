import PDFTemplateService from "@/services/PDFTemplateService.js";

const state = {
  templateList: [],
  currentTemplate: {
    _id: null,
    active: true,
    type: null,
    template: "",
  },
};

const getters = {
  getTemplateList(state) {
    return state.templateList;
  },
  getCurrentTemplate(state) {
    return state.currentTemplate;
  },
  getOfferTemplateList(state) {
    return state.templateList.filter((item) => item.type == "offer");
  },
  getConfirmationTemplateList(state) {
    return state.templateList.filter((item) => item.type == "confirmation");
  },
  getDeliveryTemplateList(state) {
    return state.templateList.filter((item) => item.type == "delivery");
  },
  getBillTemplateList(state) {
    return state.templateList.filter((item) => item.type == "bill");
  },
};

const actions = {
  newTemplate({ commit }) {
    commit("NEW_TEMPLATE");
  },
  fetchTemplateList({ commit }) {
    return new Promise((resolve, reject) => {
      PDFTemplateService.getAllTemplates()
        .then((response) => {
          if (response) {
            commit("SET_TEMPLATE_LIST", response.data.data);
            resolve(response);
          } else {
            reject("no data");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchTemplate({ commit }, id) {
    return new Promise((resolve, reject) => {
      PDFTemplateService.getTemplate(id)
        .then((response) => {
          if (response) {
            commit("SET_CURRENT_TEMPLATE", response.data.data);
            resolve(response);
          } else {
            reject("no data");
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveTemplate({ commit }, item) {
    return new Promise((resolve, reject) => {
      PDFTemplateService.saveTemplate({
        id: item._id,
        data: item,
      })
        .then(() => {
          //reset offer state
          commit("NEW_TEMPLATE");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  deleteTemplate({ commit }, item) {
    return new Promise((resolve, reject) => {
      PDFTemplateService.deleteTemplate(item._id)
        .then((response) => {
          if (response) {
            commit("DELETE_TEMPLATE", item);
            resolve(true);
          } else {
            reject(new Error("Error on deleting pdf template"));
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_TEMPLATE(state) {
    state.currentTemplate = {
      _id: null,
      active: true,
      type: null,
      template: "",
    };
  },
  SET_TEMPLATE_LIST(state, value) {
    state.templateList = value;
  },
  SET_CURRENT_TEMPLATE(state, value) {
    state.currentTemplate = value;
  },
  DELETE_TEMPLATE(state, item) {
    state.templateList = state.templateList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
