import DeliveryService from "@/services/DeliveryService.js";
import itemStatus from "@/helpers/itemStatus.js";

const state = {
  itemList: [],
  previewData: null,
  delivery: {
    _id: null,
    deliverynr: "",
    status: itemStatus.Draft.code,
    date: "",
    totalNetto: 0,
    totalBrutto: 0,
    telefon: "",
    mandant: "",
    customer: {},
    products: [],
    mwstList: [],
  },
};

const getters = {
  getItemList(state) {
    return state.itemList;
  },
  getItem(state, index) {
    return state.itemList[index];
  },
  getPreviewData(state) {
    return state.previewData;
  },
  getCurrent(state) {
    return state.delivery;
  },
};

const actions = {
  newDelivery({ commit }) {
    commit("NEW_DELIVERY");
  },
  resetDelivery({ commit }) {
    commit("NEW_DELIVERY");
  },
  setDelivery({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        commit("SET_DELIVERY", data);
        resolve();
      } else {
        reject("Empty data");
      }
    });
  },
  getNextNr({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      DeliveryService.getNextItem(mandant)
        .then((result) => {
          if (result && result.data) {
            resolve(result.data);
            commit();
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchDelivery({ commit }, { id, mandant }) {
    return new Promise((resolve, reject) => {
      DeliveryService.get(id, mandant)
        .then((result) => {
          commit("SET_DELIVERY", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveNewDelivery({ commit, dispatch }, { item, mandant }) {
    //let item = JSON.parse(JSON.stringify(item));
    return new Promise((resolve, reject) => {
      if (!mandant) {
        return reject(new Error("No mandant for saving set"));
      }
      dispatch("getNextNr", mandant._id)
        .then((result) => {
          item.lfdnr = result.lfdnr;
          item.deliverynr = result.item;
          item.templateID = mandant.pdfTemplates.deliveryTemplate;
          item.mandantData = mandant;

          //console.log(item);
          return DeliveryService.save({ id: null, data: item });
        })
        .then(() => {
          //Reset confirmation state
          commit("NEW_DELIVERY");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  saveDelivery({ commit }, delivery) {
    return new Promise((resolve, reject) => {
      DeliveryService.save({
        id: delivery._id,
        data: delivery,
      })
        .then(() => {
          commit("NEW_DELIVERY");
          resolve(delivery);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addProduct({ commit }, item) {
    return new Promise((resolve) => {
      commit("ADD_PRODUCT", item);
      resolve(item);
    });
  },

  removeProduct({ commit }, index) {
    return new Promise((resolve) => {
      commit("REMOVE_PRODUCT", index);
      resolve(index);
    });
  },

  deleteItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      DeliveryService.delete(item._id, item.mandant)
        .then(() => {
          commit("DELETE_ITEM", item);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchItemList({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      DeliveryService.getAll(mandant)
        .then((response) => {
          commit("SET_DELIVERY_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_DELIVERY(state) {
    state.delivery = {
      _id: null,
      deliverynr: "",
      status: itemStatus.Draft.code,
      date: "",
      totalNetto: 0,
      totalBrutto: 0,
      telefon: "",
      mandant: "",
      customer: {},
      products: [],
      mwstList: [],
    };
  },
  ADD_PRODUCT(state, value) {
    if (!state.delivery) {
      state.delivery = {};
    }

    if (!state.delivery.products) {
      state.delivery.products = [];
    }

    state.delivery.products.push(value);
  },
  REMOVE_PRODUCT(state, index) {
    state.delivery.products.splice(index, 1);
  },
  SET_DELIVERY_LIST(state, value) {
    state.itemList = value;
  },
  SET_DELIVERY(state, value) {
    state.delivery = value;
  },
  DELETE_ITEM(state, item) {
    state.itemList = state.itemList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
