import UserService from "@/services/UserService";
import enumRoles from "@/helpers/userRoles";

const state = {
  user: null,
  userlist: [],
  userRoles: enumRoles,
  isRefreshingToken: false,
};

const getters = {
  loggedIn(state) {
    return !!state.user;
  },
  getUser(state) {
    return state.user;
  },
  getUserList(state) {
    return state.userlist;
  },
  isSuperUser(state) {
    if (!state.user) return false;

    return !!(
      state.user.apps.roles.indexOf(state.userRoles.userRole.SuperUser) > -1
    );
  },
  userRoles(state) {
    return state.userRoles;
  },
  isTokenRefreshing(state) {
    return state.isTokenRefreshing;
  },
};

const actions = {
  login({ commit }, data) {
    return new Promise((resolve, reject) => {
      UserService.login(data)
        .then((response) => {
          commit("LOGIN", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  refreshToken({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (!state.isRefreshingToken) {
        state.isRefreshingToken = true;

        if (!state && !state.user) {
          reject("not logged in");
        }
        UserService.refreshToken(state.user.refresh_token)
          .then((response) => {
            //console.log("Refresh", response.data.data);
            commit("REFRESH_TOKEN", response.data.data);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          })
          .finally(() => {
            state.isRefreshingToken = false;
          });
      }
    });
  },
  loadUserFromStore({ commit }) {
    commit("LOAD_USER");
  },
  logout({ commit }) {
    UserService.logout().finally(() => {
      commit("LOGOUT");
      return Promise.resolve();
    });
  },
  clearStoredUser({ commit }) {
    commit("LOGOUT");
    return Promise.resolve();
  },
  fetchUserList({ commit }) {
    return new Promise((resolve, reject) => {
      UserService.fetchAll()
        .then((response) => {
          commit("SET_USER_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  LOGIN(state, data) {
    state.user = data.user;
    state.user.token = data.token;
    state.user.refresh_token = data.refresh_token;
    localStorage.setItem("user", JSON.stringify(state.user));
    UserService.setAuthToken(data.token);
  },
  REFRESH_TOKEN(state, data) {
    state.user.token = data.token;
    //state.user.refresh_token = data.refresh_token;
    localStorage.setItem("user", JSON.stringify(state.user));
    UserService.setAuthToken(data.token);
  },
  LOGOUT(state) {
    state.user = null;
    localStorage.removeItem("user");
    UserService.setAuthToken(null); //Reset auth token
  },
  SET_USER_LIST(state, data) {
    state.userlist = data;
  },
  LOAD_USER(state) {
    const user = localStorage.getItem("user") || null;
    if (user != null) {
      state.user = JSON.parse(user);
      UserService.setAuthToken(user.token);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
