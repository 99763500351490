import DataService from "@/services/DataService";

export default {
  getAll() {
    return DataService.apiClient.get("/setting");
  },

  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/setting/", item);
    }
  },
};
