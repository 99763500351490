export default {
  userRole: {
    SuperUser: "superuser",
    Manager: "manager",
    Customer: "customer",
    Partner: "partner",
    Supplier: "supplier",
    System: "system",
  },
};

/*
SuperUser: Darf alles
System: Darf alles und darf nur einmal vorhanden sein, darf nicht gelöscht werden können
Manager: Alles bis auf Mandanten bearbeiten
*/
