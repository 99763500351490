<template>
  <div>
    <v-select
      label="Mandant"
      name="mandant"
      ref="mandantSelectbox"
      :items="mandantenList"
      :value="value"
      :rules="rules"
      @change="updateValue"
      v-bind="$attrs"
    />
  </div>
</template>

<script>
import {eventBus} from "../../main";
export default {
  props: [
    "value",
    "rules",
    "showall",
  ],
  data: () => ({
    selected: null
  }),
  computed: {
    disabled() {
      if (!this.mandantenList || this.mandantenList.length == 1) {
        return true;
      } else {
        return false;
      }
    },
    mandantenList: {
      get() {
        let mandanten = [];
        /*let mandanten = [{ text: "Alle", value: "all" }];
        if (this.showall == false) {
          mandanten = [];
        }*/

        const user = this.$store.getters["user/getUser"];
        const mList = this.$store.getters["mandant/getMandantenList"];
        const isSuperUser = user.apps.roles.indexOf(this.$store.getters["user/userRoles"].userRole.SuperUser) >= 0;

        if(isSuperUser && this.showall == true){
          mandanten = [{ text: "Alle", value: "all" }];
        }
        
        mList.forEach((item) => {
          if(isSuperUser || (!isSuperUser && user.apps.rights.indexOf(item._id) >= 0)){
            mandanten.push({ text: item.firmenname, value: item._id });
          }
        });

        return mandanten;
      },
      set() {}
    }
  },
  methods: {
    updateValue(value) {
      this.selected = value;
      this.$store.dispatch("mandant/setWorkingMandant", this.selected).then(() => {
        eventBus.$emit("mandantChanged", this.selected);
        this.$emit("input", value)
      })
      
      
    },

    /*loadMandantenList(){
       let mandanten = [];
        /*let mandanten = [{ text: "Alle", value: "all" }];
        if (this.showall == false) {
          mandanten = [];
        }*

        const user = this.$store.getters["user/getUser"];
        const mList = this.$store.getters["mandant/getMandantenList"];
        const isSuperUser = user.apps.roles.indexOf(this.$store.getters["user/userRoles"].userRole.SuperUser) >= 0;

        if(isSuperUser && this.showall == true){
          mandanten = [{ text: "Alle", value: "all" }];
        }
        
        mList.forEach((item) => {
          if(isSuperUser || (!isSuperUser && user.apps.rights.indexOf(item._id) >= 0)){
            mandanten.push({ text: item.firmenname, value: item._id });
          }
        });

        return mandanten;
    }*/
  },
  created(){
    if(this.$store.getters["mandant/getMandantenList"] !== null && 
    this.$store.getters["mandant/getMandantenList"].length == 0){
      this.$store.dispatch("mandant/fetchMandantenList");
    }
  }
};
</script>
