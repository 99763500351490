import DataService from "@/services/DataService";

export default {
  getAll(mandant = null) {
    if (mandant) {
      return DataService.apiClient.get("/offer/" + mandant);
    } else {
      return DataService.apiClient.get("/offer");
    }
  },

  getByContact(mandant, contactId) {
    if (!contactId || !mandant) {
      return null;
    }

    return DataService.apiClient.get(`/offer/contact/${mandant}/${contactId}`);
  },

  get(id, mandant) {
    return DataService.apiClient.get("/offer/" + mandant + "/" + id);
  },
  getNextItem(mandant) {
    return DataService.apiClient.get("/mandant/" + mandant + "/next/offer");
  },
  makePdf(offer) {
    return DataService.apiClient.post(
      "/pdf/create",
      {
        templateData: offer,
        templateID: offer.mandantData.pdfTemplates.offerTemplate,
      },
      { responseType: "blob" }
    );
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/offer/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/offer/add", item.data);
    }
  },
  delete(id, mandant) {
    return DataService.apiClient.delete("/offer/" + mandant + "/" + id);
  },
};
