<template>
  <v-container>
    <v-app-bar app dark dense v-show="$vuetify.breakpoint.mdAndDown">
      <a href="https://datafieber.com" target="_blank" id="datafieberlink">
        <v-img
          id="logo"
          alt="Datafieber Name"
          class="shrink mt-1"
          contain
          src="../assets/logo.png"
        />
      </a>
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon @click="navdrawer = true" right></v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer
      app
      :permanent="!$vuetify.breakpoint.mdAndDown"
      class="grey darken-4 text--white"
      dark
      :fixed="true"
      v-model="navdrawer"
    >
      <v-layout justify-space-between column fill-height>
        <v-container>
          <v-list-item class="px-4">
            <v-list-item-title
              >{{ User.firstname }} {{ User.lastname }}</v-list-item-title
            >

            <v-btn icon @click="logout" class="pa-2">
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </v-list-item>

          <MandatenSelectbox
            v-if="User.apps.rights.length > 1 || checkAccess('superuser')"
            @input="mandantChange"
            v-model="workingMandant"
            :showall="true"
          />
          <v-divider
            v-if="User.apps.rights.length > 1 || checkAccess('superuser')"
          ></v-divider>

          <v-list dense nav>
            <!-- <v-list-item link to="/">
        <v-list-item-icon>
          <v-icon>mdi-view-dashboard</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Dashboard</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->
            <v-list-item link to="/offers">
              <v-list-item-icon>
                <v-icon>mdi-offer</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Angebote</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/confirmations">
              <v-list-item-icon>
                <v-icon>mdi-text-box-check</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Auftragsbestätigungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/deliveries">
              <v-list-item-icon>
                <v-icon>mdi-truck-delivery</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Lieferscheine</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/bills">
              <v-list-item-icon>
                <v-icon>mdi-cash</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Rechnungen</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/kontakte">
              <v-list-item-icon>
                <v-icon>mdi-account-box-multiple</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Kontakte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item link to="/products">
              <v-list-item-icon>
                <v-icon>mdi-package-variant-closed</v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>Produkte</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-divider />
            <!-- <v-list-item
        v-if="this.checkAccess(this.$store.getters['user/userRoles'].userRole.Manager)"
        link
        to="/mitarbeiter"
      >
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Mitarbeiter</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      -->

            <v-list-group
              v-if="
                this.checkAccess(
                  this.$store.getters['user/userRoles'].userRole.SuperUser
                )
              "
              :value="false"
              prepend-icon="mdi-cog"
            >
              <template v-slot:activator>
                <v-list-item-title>Einstellungen</v-list-item-title>
              </template>
              <v-list-item
                v-if="
                  this.checkAccess(
                    this.$store.getters['user/userRoles'].userRole.SuperUser
                  )
                "
                link
                to="/mandanten"
              >
                <v-list-item-content>
                  <v-list-item-title>Mandanten</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-office-building</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-if="
                  this.checkAccess(
                    this.$store.getters['user/userRoles'].userRole.SuperUser
                  )
                "
                link
                to="/user"
              >
                <v-list-item-content>
                  <v-list-item-title>Benutzer</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-account-supervisor</v-icon>
                </v-list-item-icon>
              </v-list-item>

              <v-list-item
                v-if="
                  this.checkAccess(
                    this.$store.getters['user/userRoles'].userRole.Manager
                  )
                "
                link
                to="/pdftemplates"
              >
                <v-list-item-content>
                  <v-list-item-title>PDF Templates</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-notebook-multiple</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <!--
                <v-list-item
                v-if="
                  this.checkAccess(
                    this.$store.getters['user/userRoles'].userRole.Manager
                  )
                "
                link
                to="/importexport"
              >
                <v-list-item-content>
                  <v-list-item-title>Import/Export</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>mdi-database-settings</v-icon>
                </v-list-item-icon>
              </v-list-item>
              -->
            </v-list-group>
          </v-list>
        </v-container>
        <v-container justify-end>
          <v-layout justify-center align-center column>
            <h4 style="color: #ccc; font-size: 10px">Powered by</h4>
            <a
              href="https://datafieber.com"
              target="_blank"
              id="datafieberlink"
            >
              <v-img
                id="logo"
                alt="Datafieber Name"
                class="shrink mt-1"
                contain
                src="../assets/logo.png"
              />
            </a>
            <div class="version">Version: {{ appVersion }}</div>
          </v-layout>
        </v-container>
      </v-layout>
    </v-navigation-drawer>
  </v-container>
</template>

<script>
import MandatenSelectbox from "@/components/clients/MandantenSelectbox";
export default {
  name: "Navigation",
  components: { MandatenSelectbox },
  props: ["user", "label", "linktext", "linkurl"],

  data: () => ({
    navdrawer: null,
    settingsMenu: null,
  }),
  methods: {
    mandantChange(mandant) {
      this.$store.dispatch("mandant/setWorkingMandant", mandant);
    },
    logout() {
      this.$store.dispatch("user/logout").then(() => {
        this.$router.push("/");
        this.$notify({
          group: "dataline",
          type: "info",
          title: "Logout",
          text: "Sie sind jetzt abgemeldet.",
        });
      });
    },
    checkAccess(role) {
      const user = this.User;

      if (!user) {
        return false;
      }
      if (
        user.apps.roles.indexOf(
          this.$store.getters["user/userRoles"].userRole.SuperUser
        ) >= 0
      ) {
        return true;
      } else if (user.apps.roles.indexOf(role) >= 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  computed: {
    appVersion() {
      return this.$store.getters["appVersion"];
    },
    User() {
      return this.$store.getters["user/getUser"];
    },
    workingMandant: {
      get() {
        return this.$store.getters["mandant/getWorkingMandant"];
      },
      set() {},
    },
  },

  created() {
    if (this.$store.getters["mandant/getMandantenList"].length == 0) {
      this.$store
        .dispatch("mandant/fetchMandantenList")
        .then(() => {})
        .catch((error) => {
          if (error.response.status != 401) {
            this.$notify({
              group: "dataline",
              type: "error",
              title: "Abfragefehler",
              text: "Mandanten konnten nicht abgefragt werden.",
            });
          }
        });
    }
    /* this.$store
      .dispatch("mandant/fetchMandantenList")
      .then(() => {
        return this.$store.dispatch("user/fetchUserList");
      })
      .catch((error) => {
        if (error.response.status != 401) {
          this.$notify({
            group: "dataline",
            type: "error",
            title: "Abfragefehler",
            text: "Mandanten konnten nicht abgefragt werden."
          });
        }
      });*/
  },
};
</script>

<style scoped>
.version {
  color: #ccc;
  font-size: 12px;
}
nav li:hover,
nav li.router-link-active,
nav li.router-link-exact-active {
  background-color: indianred;
  cursor: pointer;
}

#logo {
  display: inline-block;
  min-width: 100px;
  max-height: 14px;
  width: 150px;
}

#datafieberlink {
  vertical-align: middle;
}

hr {
  margin: 10px 0px;
}
</style>
