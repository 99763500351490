import moment from "moment";

const state = {
  countryList: [
    {
      code: "AF",
      nameDE: "Afghanistan",
      nameEN: "Afghanistan",
      dial_code: "+93"
    },
    {
      code: "EG",
      nameDE: "Ägypten",
      nameEN: "Egypt",
      dial_code: "+20"
    },
    {
      code: "AX",
      nameDE: "Ålandinseln",
      nameEN: "land Islands",
      dial_code: ""
    },
    {
      code: "AL",
      nameDE: "Albanien",
      nameEN: "Albania",
      dial_code: "+355"
    },
    {
      code: "DZ",
      nameDE: "Algerien",
      nameEN: "Algeria",
      dial_code: "+213"
    },
    {
      code: "AS",
      nameDE: "Amerikanisch-Samoa",
      nameEN: "AmericanSamoa",
      dial_code: "+1 684"
    },
    {
      code: "VI",
      nameDE: "Amerikanische Jungferninseln",
      nameEN: "Virgin Islands, U.S.",
      dial_code: "+1 340"
    },
    {
      code: "UM",
      nameDE: "Amerikanische Überseeinseln",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "AD",
      nameDE: "Andorra",
      nameEN: "Andorra",
      dial_code: "+376"
    },
    {
      code: "AO",
      nameDE: "Angola",
      nameEN: "Angola",
      dial_code: "+244"
    },
    {
      code: "AI",
      nameDE: "Anguilla",
      nameEN: "Anguilla",
      dial_code: "+1 264"
    },
    {
      code: "AQ",
      nameDE: "Antarktis",
      nameEN: "Antarctica",
      dial_code: null
    },
    {
      code: "AG",
      nameDE: "Antigua und Barbuda",
      nameEN: "Antigua and Barbuda",
      dial_code: "+1268"
    },
    {
      code: "GQ",
      nameDE: "Äquatorialguinea",
      nameEN: "Equatorial Guinea",
      dial_code: "+240"
    },
    {
      code: "AR",
      nameDE: "Argentinien",
      nameEN: "Argentina",
      dial_code: "+54"
    },
    {
      code: "AM",
      nameDE: "Armenien",
      nameEN: "Armenia",
      dial_code: "+374"
    },
    {
      code: "AW",
      nameDE: "Aruba",
      nameEN: "Aruba",
      dial_code: "+297"
    },
    {
      code: "AZ",
      nameDE: "Aserbaidschan",
      nameEN: "Azerbaijan",
      dial_code: "+994"
    },
    {
      code: "ET",
      nameDE: "Äthiopien",
      nameEN: "Ethiopia",
      dial_code: "+251"
    },
    {
      code: "AU",
      nameDE: "Australien",
      nameEN: "Australia",
      dial_code: "+61"
    },
    {
      code: "BS",
      nameDE: "Bahamas",
      nameEN: "Bahamas",
      dial_code: "+1 242"
    },
    {
      code: "BH",
      nameDE: "Bahrain",
      nameEN: "Bahrain",
      dial_code: "+973"
    },
    {
      code: "BD",
      nameDE: "Bangladesch",
      nameEN: "Bangladesh",
      dial_code: "+880"
    },
    {
      code: "BB",
      nameDE: "Barbados",
      nameEN: "Barbados",
      dial_code: "+1 246"
    },
    {
      code: "BY",
      nameDE: "Belarus",
      nameEN: "Belarus",
      dial_code: "+375"
    },
    {
      code: "BE",
      nameDE: "Belgien",
      nameEN: "Belgium",
      dial_code: "+32"
    },
    {
      code: "BZ",
      nameDE: "Belize",
      nameEN: "Belize",
      dial_code: "+501"
    },
    {
      code: "BJ",
      nameDE: "Benin",
      nameEN: "Benin",
      dial_code: "+229"
    },
    {
      code: "BM",
      nameDE: "Bermuda",
      nameEN: "Bermuda",
      dial_code: "+1 441"
    },
    {
      code: "BT",
      nameDE: "Bhutan",
      nameEN: "Bhutan",
      dial_code: "+975"
    },
    {
      code: "BO",
      nameDE: "Bolivien",
      nameEN: "Bolivia, Plurinational State of",
      dial_code: "+591"
    },
    {
      code: "BQ",
      nameDE: "Bonaire, Sint Eustatius und Saba",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "BA",
      nameDE: "Bosnien und Herzegowina",
      nameEN: "Bosnia and Herzegovina",
      dial_code: "+387"
    },
    {
      code: "BW",
      nameDE: "Botsuana",
      nameEN: "Botswana",
      dial_code: "+267"
    },
    {
      code: "BV",
      nameDE: "Bouvetinsel",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "BR",
      nameDE: "Brasilien",
      nameEN: "Brazil",
      dial_code: "+55"
    },
    {
      code: "VG",
      nameDE: "Britische Jungferninseln",
      nameEN: "Virgin Islands, British",
      dial_code: "+1 284"
    },
    {
      code: "IO",
      nameDE: "Britisches Territorium im Indischen Ozean",
      nameEN: "British Indian Ocean Territory",
      dial_code: "+246"
    },
    {
      code: "BN",
      nameDE: "Brunei Darussalam",
      nameEN: "Brunei Darussalam",
      dial_code: "+673"
    },
    {
      code: "BG",
      nameDE: "Bulgarien",
      nameEN: "Bulgaria",
      dial_code: "+359"
    },
    {
      code: "BF",
      nameDE: "Burkina Faso",
      nameEN: "Burkina Faso",
      dial_code: "+226"
    },
    {
      code: "BI",
      nameDE: "Burundi",
      nameEN: "Burundi",
      dial_code: "+257"
    },
    {
      code: "CV",
      nameDE: "Cabo Verde",
      nameEN: "Cape Verde",
      dial_code: "+238"
    },
    {
      code: "CL",
      nameDE: "Chile",
      nameEN: "Chile",
      dial_code: "+56"
    },
    {
      code: "CN",
      nameDE: "China",
      nameEN: "China",
      dial_code: "+86"
    },
    {
      code: "CK",
      nameDE: "Cookinseln",
      nameEN: "Cook Islands",
      dial_code: "+682"
    },
    {
      code: "CR",
      nameDE: "Costa Rica",
      nameEN: "Costa Rica",
      dial_code: "+506"
    },
    {
      code: "CI",
      nameDE: "Côte d’Ivoire",
      nameEN: "Cote d'Ivoire",
      dial_code: "+225"
    },
    {
      code: "CW",
      nameDE: "Curaçao",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "DK",
      nameDE: "Dänemark",
      nameEN: "Denmark",
      dial_code: "+45"
    },
    {
      code: "DE",
      nameDE: "Deutschland",
      nameEN: "Germany",
      dial_code: "+49"
    },
    {
      code: "DM",
      nameDE: "Dominica",
      nameEN: "Dominica",
      dial_code: "+1 767"
    },
    {
      code: "DO",
      nameDE: "Dominikanische Republik",
      nameEN: "Dominican Republic",
      dial_code: "+1 849"
    },
    {
      code: "DJ",
      nameDE: "Dschibuti",
      nameEN: "Djibouti",
      dial_code: "+253"
    },
    {
      code: "EC",
      nameDE: "Ecuador",
      nameEN: "Ecuador",
      dial_code: "+593"
    },
    {
      code: "SV",
      nameDE: "El Salvador",
      nameEN: "El Salvador",
      dial_code: "+503"
    },
    {
      code: "ER",
      nameDE: "Eritrea",
      nameEN: "Eritrea",
      dial_code: "+291"
    },
    {
      code: "EE",
      nameDE: "Estland",
      nameEN: "Estonia",
      dial_code: "+372"
    },
    {
      code: "SZ",
      nameDE: "Eswatini",
      nameEN: "Swaziland",
      dial_code: "+268"
    },
    {
      code: "FK",
      nameDE: "Falklandinseln",
      nameEN: "Falkland Islands (Malvinas)",
      dial_code: "+500"
    },
    {
      code: "FO",
      nameDE: "Färöer",
      nameEN: "Faroe Islands",
      dial_code: "+298"
    },
    {
      code: "FJ",
      nameDE: "Fidschi",
      nameEN: "Fiji",
      dial_code: "+679"
    },
    {
      code: "FI",
      nameDE: "Finnland",
      nameEN: "Finland",
      dial_code: "+358"
    },
    {
      code: "FR",
      nameDE: "Frankreich",
      nameEN: "France",
      dial_code: "+33"
    },
    {
      code: "GF",
      nameDE: "Französisch-Guayana",
      nameEN: "French Guiana",
      dial_code: "+594"
    },
    {
      code: "PF",
      nameDE: "Französisch-Polynesien",
      nameEN: "French Polynesia",
      dial_code: "+689"
    },
    {
      code: "TF",
      nameDE: "Französische Süd- und Antarktisgebiete",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "GA",
      nameDE: "Gabun",
      nameEN: "Gabon",
      dial_code: "+241"
    },
    {
      code: "GM",
      nameDE: "Gambia",
      nameEN: "Gambia",
      dial_code: "+220"
    },
    {
      code: "GE",
      nameDE: "Georgien",
      nameEN: "Georgia",
      dial_code: "+995"
    },
    {
      code: "GH",
      nameDE: "Ghana",
      nameEN: "Ghana",
      dial_code: "+233"
    },
    {
      code: "GI",
      nameDE: "Gibraltar",
      nameEN: "Gibraltar",
      dial_code: "+350"
    },
    {
      code: "GD",
      nameDE: "Grenada",
      nameEN: "Grenada",
      dial_code: "+1 473"
    },
    {
      code: "GR",
      nameDE: "Griechenland",
      nameEN: "Greece",
      dial_code: "+30"
    },
    {
      code: "GL",
      nameDE: "Grönland",
      nameEN: "Greenland",
      dial_code: "+299"
    },
    {
      code: "GP",
      nameDE: "Guadeloupe",
      nameEN: "Guadeloupe",
      dial_code: "+590"
    },
    {
      code: "GU",
      nameDE: "Guam",
      nameEN: "Guam",
      dial_code: "+1 671"
    },
    {
      code: "GT",
      nameDE: "Guatemala",
      nameEN: "Guatemala",
      dial_code: "+502"
    },
    {
      code: "GG",
      nameDE: "Guernsey",
      nameEN: "Guernsey",
      dial_code: "+44"
    },
    {
      code: "GN",
      nameDE: "Guinea",
      nameEN: "Guinea",
      dial_code: "+224"
    },
    {
      code: "GW",
      nameDE: "Guinea-Bissau",
      nameEN: "Guinea-Bissau",
      dial_code: "+245"
    },
    {
      code: "GY",
      nameDE: "Guyana",
      nameEN: "Guyana",
      dial_code: "+595"
    },
    {
      code: "HT",
      nameDE: "Haiti",
      nameEN: "Haiti",
      dial_code: "+509"
    },
    {
      code: "HM",
      nameDE: "Heard und McDonaldinseln",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "HN",
      nameDE: "Honduras",
      nameEN: "Honduras",
      dial_code: "+504"
    },
    {
      code: "IN",
      nameDE: "Indien",
      nameEN: "India",
      dial_code: "+91"
    },
    {
      code: "ID",
      nameDE: "Indonesien",
      nameEN: "Indonesia",
      dial_code: "+62"
    },
    {
      code: "IQ",
      nameDE: "Irak",
      nameEN: "Iraq",
      dial_code: "+964"
    },
    {
      code: "IR",
      nameDE: "Iran",
      nameEN: "Iran, Islamic Republic of",
      dial_code: "+98"
    },
    {
      code: "IE",
      nameDE: "Irland",
      nameEN: "Ireland",
      dial_code: "+353"
    },
    {
      code: "IS",
      nameDE: "Island",
      nameEN: "Iceland",
      dial_code: "+354"
    },
    {
      code: "IM",
      nameDE: "Isle of Man",
      nameEN: "Isle of Man",
      dial_code: "+44"
    },
    {
      code: "IL",
      nameDE: "Israel",
      nameEN: "Israel",
      dial_code: "+972"
    },
    {
      code: "IT",
      nameDE: "Italien",
      nameEN: "Italy",
      dial_code: "+39"
    },
    {
      code: "JM",
      nameDE: "Jamaika",
      nameEN: "Jamaica",
      dial_code: "+1 876"
    },
    {
      code: "JP",
      nameDE: "Japan",
      nameEN: "Japan",
      dial_code: "+81"
    },
    {
      code: "YE",
      nameDE: "Jemen",
      nameEN: "Yemen",
      dial_code: "+967"
    },
    {
      code: "JE",
      nameDE: "Jersey",
      nameEN: "Jersey",
      dial_code: "+44"
    },
    {
      code: "JO",
      nameDE: "Jordanien",
      nameEN: "Jordan",
      dial_code: "+962"
    },
    {
      code: "KY",
      nameDE: "Kaimaninseln",
      nameEN: "Cayman Islands",
      dial_code: "+ 345"
    },
    {
      code: "KH",
      nameDE: "Kambodscha",
      nameEN: "Cambodia",
      dial_code: "+855"
    },
    {
      code: "CM",
      nameDE: "Kamerun",
      nameEN: "Cameroon",
      dial_code: "+237"
    },
    {
      code: "CA",
      nameDE: "Kanada",
      nameEN: "Canada",
      dial_code: "+1"
    },
    {
      code: "KZ",
      nameDE: "Kasachstan",
      nameEN: "Kazakhstan",
      dial_code: "+7 7"
    },
    {
      code: "QA",
      nameDE: "Katar",
      nameEN: "Qatar",
      dial_code: "+974"
    },
    {
      code: "KE",
      nameDE: "Kenia",
      nameEN: "Kenya",
      dial_code: "+254"
    },
    {
      code: "KG",
      nameDE: "Kirgisistan",
      nameEN: "Kyrgyzstan",
      dial_code: "+996"
    },
    {
      code: "KI",
      nameDE: "Kiribati",
      nameEN: "Kiribati",
      dial_code: "+686"
    },
    {
      code: "CC",
      nameDE: "Kokosinseln",
      nameEN: "Cocos (Keeling) Islands",
      dial_code: "+61"
    },
    {
      code: "CO",
      nameDE: "Kolumbien",
      nameEN: "Colombia",
      dial_code: "+57"
    },
    {
      code: "KM",
      nameDE: "Komoren",
      nameEN: "Comoros",
      dial_code: "+269"
    },
    {
      code: "CG",
      nameDE: "Kongo-Brazzaville",
      nameEN: "Congo",
      dial_code: "+242"
    },
    {
      code: "CD",
      nameDE: "Kongo-Kinshasa",
      nameEN: "Congo, The Democratic Republic of the",
      dial_code: "+243"
    },
    {
      code: "HR",
      nameDE: "Kroatien",
      nameEN: "Croatia",
      dial_code: "+385"
    },
    {
      code: "CU",
      nameDE: "Kuba",
      nameEN: "Cuba",
      dial_code: "+53"
    },
    {
      code: "KW",
      nameDE: "Kuwait",
      nameEN: "Kuwait",
      dial_code: "+965"
    },
    {
      code: "LA",
      nameDE: "Laos",
      nameEN: "Lao People's Democratic Republic",
      dial_code: "+856"
    },
    {
      code: "LS",
      nameDE: "Lesotho",
      nameEN: "Lesotho",
      dial_code: "+266"
    },
    {
      code: "LV",
      nameDE: "Lettland",
      nameEN: "Latvia",
      dial_code: "+371"
    },
    {
      code: "LB",
      nameDE: "Libanon",
      nameEN: "Lebanon",
      dial_code: "+961"
    },
    {
      code: "LR",
      nameDE: "Liberia",
      nameEN: "Liberia",
      dial_code: "+231"
    },
    {
      code: "LY",
      nameDE: "Libyen",
      nameEN: "Libyan Arab Jamahiriya",
      dial_code: "+218"
    },
    {
      code: "LI",
      nameDE: "Liechtenstein",
      nameEN: "Liechtenstein",
      dial_code: "+423"
    },
    {
      code: "LT",
      nameDE: "Litauen",
      nameEN: "Lithuania",
      dial_code: "+370"
    },
    {
      code: "LU",
      nameDE: "Luxemburg",
      nameEN: "Luxembourg",
      dial_code: "+352"
    },
    {
      code: "MG",
      nameDE: "Madagaskar",
      nameEN: "Madagascar",
      dial_code: "+261"
    },
    {
      code: "MW",
      nameDE: "Malawi",
      nameEN: "Malawi",
      dial_code: "+265"
    },
    {
      code: "MY",
      nameDE: "Malaysia",
      nameEN: "Malaysia",
      dial_code: "+60"
    },
    {
      code: "MV",
      nameDE: "Malediven",
      nameEN: "Maldives",
      dial_code: "+960"
    },
    {
      code: "ML",
      nameDE: "Mali",
      nameEN: "Mali",
      dial_code: "+223"
    },
    {
      code: "MT",
      nameDE: "Malta",
      nameEN: "Malta",
      dial_code: "+356"
    },
    {
      code: "MA",
      nameDE: "Marokko",
      nameEN: "Morocco",
      dial_code: "+212"
    },
    {
      code: "MH",
      nameDE: "Marshallinseln",
      nameEN: "Marshall Islands",
      dial_code: "+692"
    },
    {
      code: "MQ",
      nameDE: "Martinique",
      nameEN: "Martinique",
      dial_code: "+596"
    },
    {
      code: "MR",
      nameDE: "Mauretanien",
      nameEN: "Mauritania",
      dial_code: "+222"
    },
    {
      code: "MU",
      nameDE: "Mauritius",
      nameEN: "Mauritius",
      dial_code: "+230"
    },
    {
      code: "YT",
      nameDE: "Mayotte",
      nameEN: "Mayotte",
      dial_code: "+262"
    },
    {
      code: "MX",
      nameDE: "Mexiko",
      nameEN: "Mexico",
      dial_code: "+52"
    },
    {
      code: "FM",
      nameDE: "Mikronesien",
      nameEN: "Micronesia, Federated States of",
      dial_code: "+691"
    },
    {
      code: "MC",
      nameDE: "Monaco",
      nameEN: "Monaco",
      dial_code: "+377"
    },
    {
      code: "MN",
      nameDE: "Mongolei",
      nameEN: "Mongolia",
      dial_code: "+976"
    },
    {
      code: "ME",
      nameDE: "Montenegro",
      nameEN: "Montenegro",
      dial_code: "+382"
    },
    {
      code: "MS",
      nameDE: "Montserrat",
      nameEN: "Montserrat",
      dial_code: "+1664"
    },
    {
      code: "MZ",
      nameDE: "Mosambik",
      nameEN: "Mozambique",
      dial_code: "+258"
    },
    {
      code: "MM",
      nameDE: "Myanmar",
      nameEN: "Myanmar",
      dial_code: "+95"
    },
    {
      code: "NA",
      nameDE: "Namibia",
      nameEN: "Namibia",
      dial_code: "+264"
    },
    {
      code: "NR",
      nameDE: "Nauru",
      nameEN: "Nauru",
      dial_code: "+674"
    },
    {
      code: "NP",
      nameDE: "Nepal",
      nameEN: "Nepal",
      dial_code: "+977"
    },
    {
      code: "NC",
      nameDE: "Neukaledonien",
      nameEN: "New Caledonia",
      dial_code: "+687"
    },
    {
      code: "NZ",
      nameDE: "Neuseeland",
      nameEN: "New Zealand",
      dial_code: "+64"
    },
    {
      code: "NI",
      nameDE: "Nicaragua",
      nameEN: "Nicaragua",
      dial_code: "+505"
    },
    {
      code: "NL",
      nameDE: "Niederlande",
      nameEN: "Netherlands",
      dial_code: "+31"
    },
    {
      code: "NE",
      nameDE: "Niger",
      nameEN: "Niger",
      dial_code: "+227"
    },
    {
      code: "NG",
      nameDE: "Nigeria",
      nameEN: "Nigeria",
      dial_code: "+234"
    },
    {
      code: "NU",
      nameDE: "Niue",
      nameEN: "Niue",
      dial_code: "+683"
    },
    {
      code: "KP",
      nameDE: "Nordkorea",
      nameEN: "Korea, Democratic People's Republic of",
      dial_code: "+850"
    },
    {
      code: "MP",
      nameDE: "Nördliche Marianen",
      nameEN: "Northern Mariana Islands",
      dial_code: "+1 670"
    },
    {
      code: "MK",
      nameDE: "Nordmazedonien",
      nameEN: "Macedonia, The Former Yugoslav Republic of",
      dial_code: "+389"
    },
    {
      code: "NF",
      nameDE: "Norfolkinsel",
      nameEN: "Norfolk Island",
      dial_code: "+672"
    },
    {
      code: "NO",
      nameDE: "Norwegen",
      nameEN: "Norway",
      dial_code: "+47"
    },
    {
      code: "OM",
      nameDE: "Oman",
      nameEN: "Oman",
      dial_code: "+968"
    },
    {
      code: "AT",
      nameDE: "Österreich",
      nameEN: "Austria",
      dial_code: "+43"
    },
    {
      code: "PK",
      nameDE: "Pakistan",
      nameEN: "Pakistan",
      dial_code: "+92"
    },
    {
      code: "PS",
      nameDE: "Palästinensische Autonomiegebiete",
      nameEN: "Palestinian Territory, Occupied",
      dial_code: "+970"
    },
    {
      code: "PW",
      nameDE: "Palau",
      nameEN: "Palau",
      dial_code: "+680"
    },
    {
      code: "PA",
      nameDE: "Panama",
      nameEN: "Panama",
      dial_code: "+507"
    },
    {
      code: "PG",
      nameDE: "Papua-Neuguinea",
      nameEN: "Papua New Guinea",
      dial_code: "+675"
    },
    {
      code: "PY",
      nameDE: "Paraguay",
      nameEN: "Paraguay",
      dial_code: "+595"
    },
    {
      code: "PE",
      nameDE: "Peru",
      nameEN: "Peru",
      dial_code: "+51"
    },
    {
      code: "PH",
      nameDE: "Philippinen",
      nameEN: "Philippines",
      dial_code: "+63"
    },
    {
      code: "PN",
      nameDE: "Pitcairninseln",
      nameEN: "Pitcairn",
      dial_code: "+872"
    },
    {
      code: "PL",
      nameDE: "Polen",
      nameEN: "Poland",
      dial_code: "+48"
    },
    {
      code: "PT",
      nameDE: "Portugal",
      nameEN: "Portugal",
      dial_code: "+351"
    },
    {
      code: "PR",
      nameDE: "Puerto Rico",
      nameEN: "Puerto Rico",
      dial_code: "+1 939"
    },
    {
      code: "MD",
      nameDE: "Republik Moldau",
      nameEN: "Moldova, Republic of",
      dial_code: "+373"
    },
    {
      code: "RE",
      nameDE: "Réunion",
      nameEN: "Réunion",
      dial_code: "+262"
    },
    {
      code: "RW",
      nameDE: "Ruanda",
      nameEN: "Rwanda",
      dial_code: "+250"
    },
    {
      code: "RO",
      nameDE: "Rumänien",
      nameEN: "Romania",
      dial_code: "+40"
    },
    {
      code: "RU",
      nameDE: "Russland",
      nameEN: "Russia",
      dial_code: "+7"
    },
    {
      code: "SB",
      nameDE: "Salomonen",
      nameEN: "Solomon Islands",
      dial_code: "+677"
    },
    {
      code: "ZM",
      nameDE: "Sambia",
      nameEN: "Zambia",
      dial_code: "+260"
    },
    {
      code: "WS",
      nameDE: "Samoa",
      nameEN: "Samoa",
      dial_code: "+685"
    },
    {
      code: "SM",
      nameDE: "San Marino",
      nameEN: "San Marino",
      dial_code: "+378"
    },
    {
      code: "ST",
      nameDE: "São Tomé und Príncipe",
      nameEN: "Sao Tome and Principe",
      dial_code: "+239"
    },
    {
      code: "SA",
      nameDE: "Saudi-Arabien",
      nameEN: "Saudi Arabia",
      dial_code: "+966"
    },
    {
      code: "SE",
      nameDE: "Schweden",
      nameEN: "Sweden",
      dial_code: "+46"
    },
    {
      code: "CH",
      nameDE: "Schweiz",
      nameEN: "Switzerland",
      dial_code: "+41"
    },
    {
      code: "SN",
      nameDE: "Senegal",
      nameEN: "Senegal",
      dial_code: "+221"
    },
    {
      code: "RS",
      nameDE: "Serbien",
      nameEN: "Serbia",
      dial_code: "+381"
    },
    {
      code: "SC",
      nameDE: "Seychellen",
      nameEN: "Seychelles",
      dial_code: "+248"
    },
    {
      code: "SL",
      nameDE: "Sierra Leone",
      nameEN: "Sierra Leone",
      dial_code: "+232"
    },
    {
      code: "ZW",
      nameDE: "Simbabwe",
      nameEN: "Zimbabwe",
      dial_code: "+263"
    },
    {
      code: "SG",
      nameDE: "Singapur",
      nameEN: "Singapore",
      dial_code: "+65"
    },
    {
      code: "SX",
      nameDE: "Sint Maarten",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "SK",
      nameDE: "Slowakei",
      nameEN: "Slovakia",
      dial_code: "+421"
    },
    {
      code: "SI",
      nameDE: "Slowenien",
      nameEN: "Slovenia",
      dial_code: "+386"
    },
    {
      code: "SO",
      nameDE: "Somalia",
      nameEN: "Somalia",
      dial_code: "+252"
    },
    {
      code: "HK",
      nameDE: "Sonderverwaltungsregion Hongkong",
      nameEN: "Hong Kong",
      dial_code: "+852"
    },
    {
      code: "MO",
      nameDE: "Sonderverwaltungsregion Macau",
      nameEN: "Macao",
      dial_code: "+853"
    },
    {
      code: "ES",
      nameDE: "Spanien",
      nameEN: "Spain",
      dial_code: "+34"
    },
    {
      code: "LK",
      nameDE: "Sri Lanka",
      nameEN: "Sri Lanka",
      dial_code: "+94"
    },
    {
      code: "BL",
      nameDE: "St. Barthélemy",
      nameEN: "Saint Barthélemy",
      dial_code: "+590"
    },
    {
      code: "SH",
      nameDE: "St. Helena",
      nameEN: "Saint Helena, Ascension and Tristan Da Cunha",
      dial_code: "+290"
    },
    {
      code: "KN",
      nameDE: "St. Kitts und Nevis",
      nameEN: "Saint Kitts and Nevis",
      dial_code: "+1 869"
    },
    {
      code: "LC",
      nameDE: "St. Lucia",
      nameEN: "Saint Lucia",
      dial_code: "+1 758"
    },
    {
      code: "MF",
      nameDE: "St. Martin",
      nameEN: "Saint Martin",
      dial_code: "+590"
    },
    {
      code: "PM",
      nameDE: "St. Pierre und Miquelon",
      nameEN: "Saint Pierre and Miquelon",
      dial_code: "+508"
    },
    {
      code: "VC",
      nameDE: "St. Vincent und die Grenadinen",
      nameEN: "Saint Vincent and the Grenadines",
      dial_code: "+1 784"
    },
    {
      code: "ZA",
      nameDE: "Südafrika",
      nameEN: "South Africa",
      dial_code: "+27"
    },
    {
      code: "SD",
      nameDE: "Sudan",
      nameEN: "Sudan",
      dial_code: "+249"
    },
    {
      code: "GS",
      nameDE: "Südgeorgien und die Südlichen Sandwichinseln",
      nameEN: "South Georgia and the South Sandwich Islands",
      dial_code: "+500"
    },
    {
      code: "KR",
      nameDE: "Südkorea",
      nameEN: "Korea, Republic of",
      dial_code: "+82"
    },
    {
      code: "SS",
      nameDE: "Südsudan",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "SR",
      nameDE: "Suriname",
      nameEN: "Suriname",
      dial_code: "+597"
    },
    {
      code: "SJ",
      nameDE: "Svalbard und Jan Mayen",
      nameEN: "Svalbard and Jan Mayen",
      dial_code: "+47"
    },
    {
      code: "SY",
      nameDE: "Syrien",
      nameEN: "Syrian Arab Republic",
      dial_code: "+963"
    },
    {
      code: "TJ",
      nameDE: "Tadschikistan",
      nameEN: "Tajikistan",
      dial_code: "+992"
    },
    {
      code: "TW",
      nameDE: "Taiwan",
      nameEN: "Taiwan, Province of China",
      dial_code: "+886"
    },
    {
      code: "TZ",
      nameDE: "Tansania",
      nameEN: "Tanzania, United Republic of",
      dial_code: "+255"
    },
    {
      code: "TH",
      nameDE: "Thailand",
      nameEN: "Thailand",
      dial_code: "+66"
    },
    {
      code: "TL",
      nameDE: "Timor-Leste",
      nameEN: "Timor-Leste",
      dial_code: "+670"
    },
    {
      code: "TG",
      nameDE: "Togo",
      nameEN: "Togo",
      dial_code: "+228"
    },
    {
      code: "TK",
      nameDE: "Tokelau",
      nameEN: "Tokelau",
      dial_code: "+690"
    },
    {
      code: "TO",
      nameDE: "Tonga",
      nameEN: "Tonga",
      dial_code: "+676"
    },
    {
      code: "TT",
      nameDE: "Trinidad und Tobago",
      nameEN: "Trinidad and Tobago",
      dial_code: "+1 868"
    },
    {
      code: "TD",
      nameDE: "Tschad",
      nameEN: "Chad",
      dial_code: "+235"
    },
    {
      code: "CZ",
      nameDE: "Tschechien",
      nameEN: "Czech Republic",
      dial_code: "+420"
    },
    {
      code: "TN",
      nameDE: "Tunesien",
      nameEN: "Tunisia",
      dial_code: "+216"
    },
    {
      code: "TR",
      nameDE: "Türkei",
      nameEN: "Turkey",
      dial_code: "+90"
    },
    {
      code: "TM",
      nameDE: "Turkmenistan",
      nameEN: "Turkmenistan",
      dial_code: "+993"
    },
    {
      code: "TC",
      nameDE: "Turks- und Caicosinseln",
      nameEN: "Turks and Caicos Islands",
      dial_code: "+1 649"
    },
    {
      code: "TV",
      nameDE: "Tuvalu",
      nameEN: "Tuvalu",
      dial_code: "+688"
    },
    {
      code: "UG",
      nameDE: "Uganda",
      nameEN: "Uganda",
      dial_code: "+256"
    },
    {
      code: "UA",
      nameDE: "Ukraine",
      nameEN: "Ukraine",
      dial_code: "+380"
    },
    {
      code: "HU",
      nameDE: "Ungarn",
      nameEN: "Hungary",
      dial_code: "+36"
    },
    {
      code: "UY",
      nameDE: "Uruguay",
      nameEN: "Uruguay",
      dial_code: "+598"
    },
    {
      code: "UZ",
      nameDE: "Usbekistan",
      nameEN: "Uzbekistan",
      dial_code: "+998"
    },
    {
      code: "VU",
      nameDE: "Vanuatu",
      nameEN: "Vanuatu",
      dial_code: "+678"
    },
    {
      code: "VA",
      nameDE: "Vatikanstadt",
      nameEN: "Holy See (Vatican City State)",
      dial_code: "+379"
    },
    {
      code: "VE",
      nameDE: "Venezuela",
      nameEN: "Venezuela, Bolivarian Republic of",
      dial_code: "+58"
    },
    {
      code: "AE",
      nameDE: "Vereinigte Arabische Emirate",
      nameEN: "United Arab Emirates",
      dial_code: "+971"
    },
    {
      code: "US",
      nameDE: "Vereinigte Staaten",
      nameEN: "United States",
      dial_code: "+1"
    },
    {
      code: "GB",
      nameDE: "Vereinigtes Königreich",
      nameEN: "United Kingdom",
      dial_code: "+44"
    },
    {
      code: "VN",
      nameDE: "Vietnam",
      nameEN: "Viet Nam",
      dial_code: "+84"
    },
    {
      code: "WF",
      nameDE: "Wallis und Futuna",
      nameEN: "Wallis and Futuna",
      dial_code: "+681"
    },
    {
      code: "CX",
      nameDE: "Weihnachtsinsel",
      nameEN: "Christmas Island",
      dial_code: "+61"
    },
    {
      code: "EH",
      nameDE: "Westsahara",
      nameEN: "",
      dial_code: ""
    },
    {
      code: "CF",
      nameDE: "Zentralafrikanische Republik",
      nameEN: "Central African Republic",
      dial_code: "+236"
    },
    {
      code: "CY",
      nameDE: "Zypern",
      nameEN: "Cyprus",
      dial_code: "+537"
    }
  ]
};

const getters = {
  getCountryList(state) {
    return state.countryList;
  },
  getCountryNameByCode(state, code) {
    state.countryList.forEach((element) => {
      if (element.code.toLowerCase() == String(code).toLocaleLowerCase()) {
        return element.name;
      }
    });
  }
};

const actions = {
  formatNr({ commit }, data) {
    console.log(data);
    let formatString = String(data[0]);
    const nr = data[1];
    const dateStart = formatString.indexOf("{");
    const dateEnd = formatString.indexOf("}", dateStart);
    const dateString = moment(new Date()).format(
      formatString.substring(dateStart + 1, dateEnd)
    );

    formatString = formatString.replace(
      formatString.substring(dateStart, dateEnd + 1),
      dateString
    );
    const lfdnrStart = formatString.indexOf("%n");
    const lfdnrEnd = formatString.indexOf("%", lfdnrStart + 1);
    const lfdnr = formatString.substring(lfdnrStart, lfdnrEnd);

    let nextLfdnr = nr;
    let lfdnrFormat = 4;
    if (lfdnr.length > 2) {
      try {
        lfdnrFormat = Number.parseInt(
          formatString.substring(lfdnrStart + 2, lfdnrEnd)
        );
      } catch (e) {
        lfdnrFormat = 4;
      }

      const prefixLen = lfdnrFormat - String(nr).length;
      if (prefixLen > 0) {
        nextLfdnr = "";
        for (let n = 0; n < prefixLen; n++) {
          nextLfdnr += "0";
        }
        nextLfdnr += nr;
      }
    }

    formatString = formatString.replace(
      formatString.substring(lfdnrStart, lfdnrEnd + 1),
      nextLfdnr
    );
    commit();
    return formatString;
  }
};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
