import DataService from "@/services/DataService";

export default {
  getAll() {
    return DataService.apiClient.get("/contact");
  },
  getList(start, perPage, query) {
    let url = `/contact?page=${start}&perPage=${perPage}`;

    if (query) {
      url = `/contact?page=${start}&perPage=${perPage}&query=${encodeURIComponent(
        query
      )}`;
    }
    return DataService.apiClient.get(url);
  },

  get(id) {
    return DataService.apiClient.get("/contact/" + id);
  },
  findByName(name) {
    return DataService.apiClient.get("/contact/findbyname/" + name);
  },
  findClubs() {
    return DataService.apiClient.get("/contact/findclubs/");
  },
  findClub(name) {
    return DataService.apiClient.get("/contact/findclub/" + name);
  },
  getLastItem() {
    return DataService.apiClient.get("/contact/last/");
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/contact/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/contact/add", item.data);
    }
  },
  delete(id) {
    return DataService.apiClient.delete("/contact/delete/" + id);
  },

  searchAddress(address) {
    return DataService.apiClient.post("/contact/searchAddress", {
      query: address,
    });
  },

  getAddressDetails(place_id) {
    return DataService.apiClient.get("/contact/addressdetails/" + place_id);
  },
};
