import BillService from "@/services/BillService.js";
import itemStatus from "@/helpers/itemStatus.js";

const state = {
  itemList: [],
  previewData: null,

  bill: {
    _id: null,
    billnr: "",
    status: itemStatus.Draft.code,
    validTS: "",
    billdate: "",
    paydate: "",
    totalNetto: 0,
    totalBrutto: 0,
    mandant: "",
    customer: {},
    products: [],
    mwstList: [],
  },
};

const getters = {
  getItemList(state) {
    return state.itemList;
  },
  getItem(state, index) {
    return state.itemList[index];
  },
  getPreviewData(state) {
    return state.previewData;
  },
  getCurrent(state) {
    return state.bill;
  },
};

const actions = {
  newBill({ commit }) {
    commit("NEW_BILL");
  },
  resetBill({ commit }) {
    commit("NEW_BILL");
  },
  setBill({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        commit("SET_BILL", data);
        resolve();
      } else {
        reject("Empty data");
      }
    });
  },
  getNextNr({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      BillService.getNextItem(mandant)
        .then((result) => {
          if (result && result.data) {
            resolve(result.data);
            commit();
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchBill({ commit }, { id, mandant }) {
    return new Promise((resolve, reject) => {
      BillService.get(id, mandant)
        .then((result) => {
          commit("SET_BILL", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveNewBill({ commit, dispatch }, { item, mandant }) {
    //let item = JSON.parse(JSON.stringify(offerItem));
    return new Promise((resolve, reject) => {
      if (!mandant) {
        return reject(new Error("No mandant for saving set"));
      }
      dispatch("getNextNr", mandant._id)
        .then((result) => {
          item.lfdnr = result.lfdnr;
          item.billnr = result.item;
          item.templateID = mandant.pdfTemplates.billTemplate;
          item.mandantData = mandant;

          //console.log(item);
          return BillService.save({ id: null, data: item });
        })
        .then(() => {
          //Reset bill state
          commit("NEW_BILL");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveBill({ commit }, bill) {
    return new Promise((resolve, reject) => {
      BillService.save({
        id: bill._id,
        data: bill,
      })
        .then(() => {
          commit("NEW_BILL");
          resolve(bill);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addProduct({ commit }, item) {
    return new Promise((resolve) => {
      commit("ADD_PRODUCT", item);
      resolve(item);
    });
  },

  removeProduct({ commit }, index) {
    return new Promise((resolve) => {
      commit("REMOVE_PRODUCT", index);
      resolve(index);
    });
  },

  deleteItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      BillService.delete(item._id, item.mandant)
        .then(() => {
          commit("DELETE_ITEM", item);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchItemList({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      BillService.getAll(mandant)
        .then((response) => {
          commit("SET_BILL_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_BILL(state) {
    state.bill = {
      _id: null,
      billnr: "",
      status: itemStatus.Draft.code,
      validTS: "",
      billdate: "",
      paydate: "",
      totalNetto: 0,
      totalBrutto: 0,
      mandant: "",
      customer: {},
      products: [],
      mwstList: [],
    };
  },
  ADD_PRODUCT(state, value) {
    if (!state.bill) {
      state.bill = {};
    }

    if (!state.bill.products) {
      state.bill.products = [];
    }

    state.bill.products.push(value);
  },
  REMOVE_PRODUCT(state, index) {
    state.bill.products.splice(index, 1);
  },
  SET_BILL_LIST(state, value) {
    state.itemList = value;
  },
  SET_BILL(state, value) {
    state.bill = value;
  },
  DELETE_ITEM(state, item) {
    state.itemList = state.itemList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
