import DataService from "@/services/DataService";

export default {
  getAll(mandant = null) {
    if (mandant) {
      return DataService.apiClient.get("/confirmation/" + mandant);
    } else {
      return DataService.apiClient.get("/confirmation");
    }
  },
  getByContact(mandant, contactId) {
    if (!contactId || !mandant) {
      return null;
    }

    return DataService.apiClient.get(
      `/confirmation/contact/${mandant}/${contactId}`
    );
  },

  get(id, mandant) {
    return DataService.apiClient.get("/confirmation/" + mandant + "/" + id);
  },
  getNextItem(mandant) {
    return DataService.apiClient.get(
      "/mandant/" + mandant + "/next/confirmation"
    );
  },
  makePdf(item) {
    return DataService.apiClient.post(
      "/pdf/create",
      {
        templateData: item,
        templateID: item.mandantData.pdfTemplates.confirmTemplate,
      },
      { responseType: "blob" }
    );
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch(
        "/confirmation/edit/" + item.id,
        item.data
      );
    } else {
      return DataService.apiClient.post("/confirmation/add", item.data);
    }
  },
  delete(id, mandant) {
    return DataService.apiClient.delete("/confirmation/" + mandant + "/" + id);
  },
};
