import DataService from "@/services/DataService";

export default {
  getAllTemplates() {
    return DataService.apiClient.get("/pdf/template");
  },
  getTemplate(id) {
    return DataService.apiClient.get("/pdf/template/id/" + id);
  },
  saveTemplate(item) {
    if (item.id != null) {
      return DataService.apiClient.patch("/pdf/template/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/pdf/template/", item.data);
    }
  },
  deleteTemplate(id) {
    return DataService.apiClient.delete("/pdf/template/" + id);
  },
  getActiveByType(type) {
    return DataService.apiClient.get("/pdf/active/" + type);
  },
  get(id) {
    return DataService.apiClient.get("/pdf/" + id);
  },
  getPdfByObjectId(objectId, type) {
    return DataService.apiClient.get("/pdf/byobject/" + objectId + "/" + type);
  },

  makePdf(offer) {
    return DataService.apiClient.post(
      "/pdf/create",
      {
        templateData: offer,
        type: "offer",
      },
      { responseType: "blob" }
    );
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/pdf/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/pdf/add", item.data);
    }
  },
  delete(id) {
    return DataService.apiClient.delete("/pdf/delete/" + id);
  },
};
