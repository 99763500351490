<template>
  <v-app id="main">
    <v-container id="background"></v-container>
    <v-container class="loader" v-show="isLoading">
      <v-layout align-center justify-center column fill-height>
        <v-flex row align-center>
          <v-progress-circular
            indeterminate
            :size="50"
            color="purple"
            class=""
          ></v-progress-circular>
        </v-flex>
      </v-layout>
    </v-container>
    <Navigation
      v-if="loggedIn"
      label="DataLine CRM V1.0 beta2"
      linktext="datafieber.com"
      linkurl="https://datafieber.com"
    />
    <v-main style="padding-top:0px;">
      <router-view></router-view>
      <notifications group="dataline" position="bottom right" />
    </v-main>
  </v-app>
</template>

<script>
import Navigation from "./components/Navigation";
import DataService from "@/services/DataService";
import axios from "axios";

export default {
  name: "App",
  components: {
    Navigation
  },
  beforeCreate() {
    this.$store.dispatch("user/loadUserFromStore");

    DataService.apiClient.interceptors.request.use(
      (config) => {
        var user = JSON.parse(localStorage.getItem("user"));
        //console.log("REQ", config);
        if (user == null && config.url != "/user/login") {
          throw new axios.Cancel("not logged in");
        }
        if (user != null) {
          config.headers.Authorization = `Bearer ${user.token}`;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  },
  created() {},

  data: () => ({
    //
  }),

  computed: {
    loggedIn() {
      return this.$store.getters["user/loggedIn"];
    },
    isLoading() {
      return this.$store.getters["appLoading"];
    }
  }
};
</script>

<style>
#background {
  background-image: url(./assets/bg-business.jpg);
  background-attachment: fixed;
  background-size: cover;
  filter: blur(4px) !important;
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100%;
  position: fixed;
}

.maincard {
  background-color: rgba(255, 255, 255, 0.8) !important;
}

#content {
  background: rgba(255, 255, 255, 0.4);
}

.loader {
  background-color: rgba(0, 0, 0, 0.8);
  flex: 1 1 auto;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100% !important;
  position: fixed;
  z-index: 9999;
}
h1,
h2,
h3
{
  padding: 20px 0px;
  margin-bottom: 10px;
}
</style>
