import OfferService from "@/services/OfferService.js";
import itemStatus from "@/helpers/itemStatus.js";

const state = {
  itemList: [],
  previewData: null,

  offer: {
    _id: null,
    offernr: "",
    status: itemStatus.Draft.code,
    date: "",
    validTS: "",
    totalNetto: 0,
    totalBrutto: 0,
    mandant: "",
    customer: {},
    products: [],
    mwstList: [],
  },
};

const getters = {
  getItemList(state) {
    return state.itemList;
  },
  getPreviewData(state) {
    return state.previewData;
  },
  getCurrent(state) {
    return state.offer;
  },
};

const actions = {
  newOffer({ commit }) {
    commit("NEW_OFFER");
  },
  resetOffer({ commit }) {
    commit("NEW_OFFER");
  },
  setOffer({ commit }, data) {
    return new Promise((resolve, reject) => {
      if (data) {
        commit("SET_OFFER", data);
        resolve();
      } else {
        reject("Empty data");
      }
    });
  },
  getNextNr({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      OfferService.getNextItem(mandant)
        .then((result) => {
          if (result && result.data) {
            resolve(result.data);
            commit();
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  fetchOffer({ commit }, { id, mandant }) {
    return new Promise((resolve, reject) => {
      OfferService.get(id, mandant)
        .then((result) => {
          commit("SET_OFFER", result.data.data);
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  saveNewOffer({ state, commit, dispatch }, { item, mandant }) {
    state.appLoading = true;
    return new Promise((resolve, reject) => {
      if (!mandant) {
        return reject(new Error("No mandant for saving set"));
      }
      dispatch("getNextNr", mandant._id)
        .then((result) => {
          item.lfdnr = result.lfdnr;
          item.offernr = result.item;
          item.templateID = mandant.pdfTemplates.offerTemplate;
          item.mandantData = mandant;

          //console.log(item);
          return OfferService.save({ id: null, data: item });
        })
        .then(() => {
          //Reset offer state
          commit("NEW_OFFER");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        })
        .finally(() => {
          state.appLoading = false;
        });
    });
  },
  saveOffer({ commit }, item) {
    return new Promise((resolve, reject) => {
      OfferService.save({
        id: item._id,
        data: item,
      })
        .then(() => {
          //reset offer state
          commit("NEW_OFFER");
          resolve(item);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  addProduct({ commit }, item) {
    return new Promise((resolve) => {
      commit("ADD_PRODUCT", item);
      resolve(item);
    });
  },

  removeProduct({ commit }, index) {
    return new Promise((resolve) => {
      commit("REMOVE_PRODUCT", index);
      resolve(index);
    });
  },

  deleteItem({ commit }, item) {
    return new Promise((resolve, reject) => {
      OfferService.delete(item._id, item.mandant)
        .then(() => {
          commit("DELETE_ITEM", item);
          resolve(true);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  fetchItemList({ commit }, mandant) {
    return new Promise((resolve, reject) => {
      OfferService.getAll(mandant)
        .then((response) => {
          commit("SET_OFFER_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
};

const mutations = {
  NEW_OFFER(state) {
    state.offer = {
      _id: null,
      offernr: "",
      status: itemStatus.Draft.code,
      date: "",
      validTS: "",
      totalNetto: 0,
      totalBrutto: 0,
      mandant: "",
      customer: {},
      products: [],
      mwstList: [],
    };
  },
  ADD_PRODUCT(state, value) {
    if (!state.offer) {
      state.offer = {};
    }

    if (!state.offer.products) {
      state.offer.products = [];
    }

    state.offer.products.push(value);
  },
  REMOVE_PRODUCT(state, index) {
    state.offer.products.splice(index, 1);
  },
  SET_OFFER_LIST(state, value) {
    state.itemList = value;
  },
  SET_OFFER(state, value) {
    state.offer = value;
  },
  DELETE_ITEM(state, item) {
    state.itemList = state.itemList.filter((element) => {
      return element._id != item._id;
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
