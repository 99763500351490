import SettingService from "@/services/SettingService.js";

const state = {
  settings: {}
};

const getters = {};

const actions = {
  fetchSettings({ commit }) {
    return new Promise((resolve, reject) => {
      SettingService.getAll()
        .then((response) => {
          commit("SET_SETTING_LIST", response.data.data);
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
};

const mutations = {
  SET_SETTING_LIST(state, value) {
    state.settings = value;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
