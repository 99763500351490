export default {
  Draft: {
    code: 10,
    title: "Entwurf"
  },
  Completed: {
    code: 11,
    title: "Fertiggestellt"
  },
  Sended: {
    code: 12,
    title: "Gesendet"
  },
  Seen: {
    code: 13,
    title: "Gesehen"
  },
  Accepted: {
    code: 20,
    title: "Angenommen"
  },
  Confirmed: {
    code: 30,
    title: "Bestätigt"
  },
  Delivered: {
    code: 40,
    title: "Geliefert"
  },
  Invoiced: {
    code: 50,
    title: "Fakturiert"
  },
  Payed: {
    code: 60,
    title: "Bezahlt"
  },
  Overdue: {
    code: 70,
    title: "Überfällig"
  },
  Warning1: {
    code: 80,
    title: "1. Mahnung"
  },
  Warning2: {
    code: 90,
    title: "2. Mahnung"
  },
  Warning3: {
    code: 100,
    title: "3. Mahnung"
  }
};
