import DataService from "@/services/DataService";

export default {
  getAll(mandant = null) {
    if (mandant) {
      return DataService.apiClient.get("/bill/" + mandant);
    } else {
      return DataService.apiClient.get("/bill");
    }
  },

  getByContact(mandant, contactId) {
    if (!contactId || !mandant) {
      return null;
    }

    return DataService.apiClient.get(`/bill/contact/${mandant}/${contactId}`);
  },

  get(id, mandant) {
    return DataService.apiClient.get("/bill/" + mandant + "/" + id);
  },
  getNextItem(mandant) {
    return DataService.apiClient.get("/mandant/" + mandant + "/next/bill");
  },
  makePdf(bill) {
    return DataService.apiClient.post(
      "/pdf/create",
      {
        templateData: bill,
        templateID: bill.mandantData.pdfTemplates.billTemplate,
      },
      { responseType: "blob" }
    );
  },
  save(item) {
    //console.log(item);
    if (item.id != null) {
      return DataService.apiClient.patch("/bill/edit/" + item.id, item.data);
    } else {
      return DataService.apiClient.post("/bill/add", item.data);
    }
  },
  delete(id, mandant) {
    return DataService.apiClient.delete("/bill/" + mandant + "/" + id);
  },
};
