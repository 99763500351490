import DataService from "@/services/DataService";

export default {
  getAll() {
    return DataService.apiClient.get("/mandant");
  },

  get(id) {
    return DataService.apiClient.get("/mandant/" + id);
  },
  upload(file, onUploadProgress) {
    let formData = new FormData();

    formData.append("logo", file);

    return DataService.apiClient.post("/mandant/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      onUploadProgress
    });
  },
  save(item) {
    //console.log(item);
    //let formData = this.getFormData(item.data);
    var postData = JSON.stringify(item);
    var formData = new FormData();
    formData.append("data", postData);
    formData.append("logo", item.logo);

    if (item.id != null) {
      return DataService.apiClient.patch("/mandant/edit/" + item.id, formData, {
        "content-type": "multipart/form-data"
      });
    } else {
      return DataService.apiClient.post("/mandant/add", formData, {
        "content-type": "multipart/form-data"
      });
    }
  },
  delete(id) {
    return DataService.apiClient.delete("/mandant/delete/" + id);
  },
  getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => formData.append(key, object[key]));
    return formData;
  }
};
